<template>
  <div class="element materialShadow">
    <div class="photos-and-drawer">
      <transition-group name="list" tag="div" class="photoList">
        <div v-for="(item, idx) in images" :key="item.id" class="photoListItemWrapper">
          <div class="photoListItem">
            <template v-if="item.type==='current'">
              <image-item :item="item" show-delete-button="false"/>
            </template>
            <template v-else>
              <div class="spinner-block">
                <icon name="fa-spin fa-cog"/>
              </div>
              <image-item-uploading :item="item"/>
            </template>
          </div>
          <div class="image-number">{{ idx + 1 }}</div>
          <template v-if="item.type==='current'">
            <div class="drawer-toggle-container">
              <button class="lsc-button lsc-rounded drawer-toggle-button"
                      :class="{ selected: drawerIndex === idx }"
                      @click="toggleDrawer(idx)">
                <icon :name="drawerIndex === idx ? 'fa-times' : 'fa-caret-down'"/></button>
            </div>
            <a v-if="isCoverImage(item)" class="badge cover-image"><icon name="fa-star"/></a>
            <a v-if="item.isCustomerImage" class="badge customer-image"><icon name="fa-home"/></a>
          </template>
        </div>
      </transition-group>
      <div v-if="images.length === 0" class="noImages" @click="clickCameraButton()">{{
          maxImageCount > 1 ? x('noImages') : x('noImage')
        }}.
      </div>
      <p v-if="isLoadingCustomerImages" class="small-message">{{ x('checkingForCustomerImagesDots')}}</p>
    </div>
    <div class="image-drawer-outer">
      <div class="image-drawer" :class="{ open: drawerIndex > -1, closed: drawerIndex === -1 }">
        <div class="image-drawer-inner">
          <ImageDrawer v-if="drawerIndex > -1"
                       v-model="images[drawerIndex]"
                       :is-cover-image="drawerItem && (drawerItem.name === coverImage)"
                       :is-customer-image="drawerItem.isCustomerImage"
                       @cover-image="setCoverImage"
                       @delete-image="deleteImage"
                       @toggle-customer-image="toggleCustomerImage"
          />
        </div>
      </div>
    </div>
    <button v-if="drawerIndex === -1"
            type="button" class="lsc-button lsc-rounded-10 photobutton"
            :class="{ green: !maxImagesReached, grey: maxImagesReached }"
            @click="clickCameraButton()">
      <i class="fa fa-camera"/>
    </button>
    <teleport to="#uploadContainer">
      <CloudinaryUpload ref="uploadComponent"
                        :upload-multiple="uploadMultiple"
                        @add="addHandler"
                        @progress="progressHandler"
                        @done="doneHandler"
                        @fail="failHandler"
      />
    </teleport>
  </div>
</template>

<script>
  import { find, findIndex } from 'lodash';
  import { mapState } from 'vuex';
  import { httpGet, httpPost } from '@/classes/httpHelper';
  import ComponentEventNames from '@/enums/component-event-names';
  import CloudinaryUpload from '@/components/ImageUpload/CloudinaryUpload';
  import ImageDrawer from './ImageDrawer';
  import ImageItemUploading from './ImageItemUploading';
  import ImageItem from './ImageItem';
  import translate from '../Mixins/Translate';

  export default {
    name: 'image-list',
    components: {
      CloudinaryUpload,
      ImageDrawer,
      'image-item-uploading': ImageItemUploading,
      'image-item': ImageItem,
    },
    emits: [
      ComponentEventNames.updateModelValue,
      'uploadingCount',
      'setCoverImage',
    ],
    mixins: [translate],
    props: {
      editMode: {
        type: Boolean,
        default: false,
      },
      modelValue: {
        // These are the pictures of the current visit, will have pictures if in edit mode.
        // This property implements 'v-model' support along with the 'input' emitted event.
        // Therefore, this property should not be filled explicitly, rather, a 'v-model'
        // attribute should be used.
      },
      imageViewMode: {
        /* imageViewMode indicates the way image should be displayed when it is uploaded or
        when image is available in edit mode
        0: Normal display
        1: enlarge Image
        2: enlarged cropped image with fixed height
        */
        type: Number,
        default: 0,
      },
      coverImage: {
        // This is the name of the image that is currently the cover image.
        type: String,
      },
      /** This is the customer for whom we also want to show a list of pictures
       * in addition to the ones associated with the visit. */
      customerId: {
        type: String,
      },
    },
    data() {
      return {
        images: this.modelValue
          .map((item) => ({
            name: item.name,
            type: item.type || 'current',
            isCustomerImage: item.isCustomerImage || false,
            wasOriginallyCustomerImage: item.wasOriginallyCustomerImage || false,
            id: item.id || this.generateRandomImageId(),
            comment: item.comment || '',
          })),
        /**
         * A list of images that have been marked for deletion during edit.
         * They are not actually deleted until the user presses OK,
         * since we need to retain them in case the user cancels the edit.
         */
        deletedImages: [],
        /**
         * A list of images that have been added during edit.
         * They should be deleted if cancel is pressed.
         */
        addedImages: [],
        imageHeight: 70,
        imageWidth: 100,
        uploadingCounter: 0,
        /**
         * -1 means the drawer is closed.
         * Otherwise it contains the index of the image being shown in the drawer.
         */
        drawerIndex: -1,
        /** The originally loaded list of customer images. */
        originalCustomerImages: [],
        /** Index in the images list where new elements should be inserted */
        insertPosition: 0,
        /** Flag that indicates if we are currently loading customer images. */
        isLoadingCustomerImages: true,
      };
    },
    computed: {
      maxImagesReached() {
        return this.visitImages.length >= this.maxImageCount;
      },
      drawerItem() {
        if (this.drawerIndex === -1) return null;
        return this.images[this.drawerIndex];
      },
      uploadMultiple() {
        return this.maxImageCount > 1;
      },
      visitImages() {
        return this.images.filter((img) => !img.isCustomerImage);
      },
      maxImageCount() {
        return parseInt(this.config.obj_texts.MaxImageCountPrVisit, 10);
      },
      ...mapState(['config', 'user']),
    },
    watch: {
      customerId: {
        immediate: true,
        async handler() {
          await this.loadCustomerImages();
        },
      },
      images: {
        deep: true,
        handler(newValue) {
          this.$emit(ComponentEventNames.updateModelValue, newValue);
        },
      },
    },
    async mounted() {
      // setUploadEnabled shows the cloudinaryUpload component.
      // This must be done and nextTick'ed, before the listeners can
      // be set up.
      if (this.user.directToCamera === true) {
        console.log('clicking the button');
        this.$refs.uploadComponent.clickButton();
      }
      this.checkForMissingCoverImage();
    },
    methods: {
      generateRandomImageId() {
        return Math.floor(Math.random() * 100000);
      },
      // Checks for a cover image and sets to the first visit image if none are present.
      checkForMissingCoverImage() {
        // Check among the visit images, not customer images.
        const coverIdx = this.visitImages.findIndex((i) => i.name === this.coverImage);
        if (coverIdx === -1 && this.visitImages.length > 0) {
          console.log('Setting a missing cover image.');
          const newCoverIndex = findIndex(this.images, { isCustomerImage: false });
          this.$emit('setCoverImage', this.images[newCoverIndex].name);
        }
      },
      addHandler(data) {
        // Detected that new file(s) have been added to the download queue.
        const filename = data.name;
        this.images.splice(this.insertPosition, 0, {
          name: filename,
          type: 'new',
          progress: 0,
          isCustomerImage: false,
          wasOriginallyCustomerImage: false,
          comment: '',
          id: this.generateRandomImageId(),
        });
        this.insertPosition += 1;

        this.uploadingCounter += 1;
        this.$emit('uploadingCount', this.uploadingCounter);
      },
      progressHandler(data) {
        const percent = Math.round((data.loaded * 100.0) / data.total);
        const item = find(this.images, ['name', data.file.name]);
        if (item) {
          item.progress = percent;
        }
      },
      doneHandler(data) {
        if (data.textStatus === 'success') {
          const imageId = data.result.public_id;
          const uploadFilename = data.file.name;
          const imageVersion = data.result.version || '';
          const imageObj = find(this.images, ['name', uploadFilename]);
          // Note that any properties set here must be already set in the add handler,
          // otherwise they will not be reactive.
          imageObj.name = imageId;
          imageObj.type = 'current';
          imageObj.version = imageVersion;
          imageObj.isCustomerImage = false;
          imageObj.wasOriginallyCustomerImage = false;
          this.addedImages.push(imageObj);

          this.uploadingCounter -= 1;
          this.$emit('uploadingCount', this.uploadingCounter);
          this.checkForMissingCoverImage();
        } else {
          // eslint-disable-next-line no-alert
          alert(this.config.translation.uploadFailedTryAgain);
          this.uploadingCounter -= 1;
          this.$emit('uploadingCount', this.uploadingCounter);
        }
      },
      failHandler(e, data) {
        // eslint-disable-next-line no-alert
        alert(this.config.translation.uploadFailedTryAgain);
        console.warn('Upload failed.', e, data);
        this.uploadingCounter -= 1;
        this.$emit('uploadingCount', this.uploadingCounter);
      },
      clickCameraButton() {
        if (this.uploadingCounter > 0) return;
        if (this.maxImagesReached) {
          console.warn('Maximum number of images reached.');
          return;
        }
        this.$refs.uploadComponent.clickButton();
      },
      toggleDrawer(index) {
        if (this.drawerIndex === index) {
          this.drawerIndex = -1;
        } else {
          this.drawerIndex = index;
        }
      },

      async deleteImage(item) {
        this.drawerIndex = -1;
        const publicId = item.name;
        const index = findIndex(this.images, (o) => o.name === publicId);
        this.images.splice(index, 1);
        if (this.editMode) {
          this.deletedImages.push(item);
        } else {
          await this.sendDeleteToServer(item);
        }

        if (this.insertPosition > index) this.insertPosition -= 1;

        this.checkForMissingCoverImage();
        this.drawerIndex = -1;
      },

      async setCoverImage(item) {
        this.$emit('setCoverImage', item.name);
      },

      async loadCustomerImages() {
        if (!this.customerId) {
          this.isLoadingCustomerImages = false;
          return;
        }
        this.isLoadingCustomerImages = true;
        await this.$nextTick();

        const customerImages = await httpGet(`customers/${this.customerId}/pictures`);
        for (const img of customerImages) {
          img.isCustomerImage = true;
          img.wasOriginallyCustomerImage = true;
          img.type = 'current';
        }

        this.originalCustomerImages = customerImages;

        const images = this.images.filter((img) => !img.wasOriginallyCustomerImage);
        this.images = images.concat(this.originalCustomerImages);
        this.isLoadingCustomerImages = false;
      },

      /** This toggles the drawerItem.isCustomerImage flag. */
      toggleCustomerImage(isCustomerImage) {
        this.images[this.drawerIndex].isCustomerImage = isCustomerImage;
      },

      isCoverImage(item) {
        if (!item) return false;
        return this.coverImage === item.name;
      },

      async sendDeleteToServer(item) {
        await httpPost('oldimages', [item.name]);
        console.log('Image deleted.');
      },

      /**
       * In case of edit mode, destructive image changes have not been committed.
       * They can be committed by calling this method.
       */
      async commitImageChanges() {
        // Removed images should be removed now.
        // Added images are already uploaded, they are all right.
        this.deletedImages.forEach(await this.sendDeleteToServer);
      },

      async rollbackImageChanges() {
        // Removed images are not really removed, so they can be ignored when we don't save changes.
        // Added images should be removed again.
        this.addedImages.forEach(await this.sendDeleteToServer);
      },
    },
  };
</script>

<style scoped lang="scss">
.photos-and-drawer {
  overflow-x: scroll;

  .photoList {
    white-space: nowrap;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .photoListItemWrapper {
      position: relative;
      width: 100px;
      margin: 0 10px 0 0;
      display: inline-block;

      .photoListItem {
        height: 70px;
        width: 100px;
        background: #efefef;
        overflow: hidden;
        border-radius: 10px;
        //border-bottom: 4px solid rgba(0, 0, 0, 0.3);
        position: relative;

        &.hasImage {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &.used {
          border: 2px solid #636363;
        }

        .spinner-block {
          width: 20px;
          margin: auto;
          padding-top: 17px;
          color: rgba(0, 0, 0, 0.3);
          font-size: 30px;
        }

      }

      .image-number {
        position: absolute;
        top: 0;
        left: 0;
        background-color: white;
        color: var(--ThemeTakePicH2Color);
        font-weight: 700;
        font-size: 14px;
        padding-left: 5px;
        padding-right: 5px;
        border-bottom-right-radius: 4px;
      }

      .drawer-toggle-container {
        text-align: center;
        height: 24px;

        .drawer-toggle-button {
          margin: auto 0;
          height: 15px;
          background-color: #efefef;
          color: #a0a0a0;
          border-bottom: 2px solid #a0a0a0;

          &.selected {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom: 6px solid #efefef;
          }

          i {
            position: relative;
            top: -10px;
            left: 7px;
          }

          &:focus {
            outline: none;
          }
        }
      }

      .badge {
        display: flex;
        position: absolute;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        border-radius: 100%;
      }

      .cover-image {
        right: 0;
        bottom: 4px;
        background: var(--ThemeCoverButtonBgSel);
        height: 18px;
        width: 18px;
        color: white;
      }

      .customer-image {
        left: 0;
        bottom: 4px;
        background: var(--ThemeIdeaButton);
        height: 18px;
        width: 18px;
        color: white;
      }

    }

  }

  .noImages {
    background-color: #efefef;
    border-radius: 10px;
    height: 100px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .small-message {
    font-size: 12px;
    color: var(--ThemeAlertColor);
    font-style: italic;
  }
}

// the actual size of the box
.image-drawer-outer {
  margin: -3px -10px 10px -10px;
  overflow: hidden;

  // This is drawn further out than the border, to hide the drop shadows on the sides
  .image-drawer {
    margin: 0 -10px;
    background-color: #efefef;
    box-shadow: inset 0 0 3px 0 #a0a0a0;
    overflow: hidden;
    transition: height 0.2s ease-in-out;

    &.open {
      height: 220px;
    }

    &.closed {
      height: 0;
    }

    // This is the size of the contents
    .image-drawer-inner {
      margin: 10px 20px;
    }
  }
}

button.photobutton {
  text-align: center;
  font-size: 20px;
  margin: auto;
  width: 100%;
  padding-top: 7px;
  padding-bottom: 4px;

  &:focus {
    outline: none;
  }

  i {
    margin: 0;
  }
}

.list-enter-active, .list-leave-active {
  transition: all 0.3s ease-in-out;
}

.list-enter, .list-leave-to {
  opacity: 0;
}

.list-move {
  transition: transform 0.3s ease-in-out;
}
</style>
