<template>
  <div>
    <dt class="customerSelect">
      <a @click="openCustomerList()" class="list_select right costumer_button_icon">
        <i v-if="!customersLoading" class="fa fa-chevron-right"/>
        <i v-if="customersLoading" class="fa fa-cog fa-spin"/>
      </a>

      <h2 class='tp_headline'>{{ x('customer') }}:</h2>
    </dt>
    <dd class="customer">
      <span :class="{ 'loading': isLoadingLocation === true }">
        <span v-if="isLoadingLocation">
          <i class="fa fa-cog fa-spin"/> {{ x('findingNearestCustomerDots') }}
        </span>
        {{ customerMessage }}
          <a v-if="showOpenGeoHelp" @click="openGeoHelp()">{{ x('geoHelp') }}</a>
        <p v-if="customerMessage2">
          {{ customerMessage2 }}
        </p>
      </span>
    </dd>
  </div>
</template>

<script>
  import { defineAsyncComponent } from 'vue';
  import { mapState, mapGetters } from 'vuex';
  import { httpGet } from '@/classes/httpHelper';
  import ComponentEventNames from '@/enums/component-event-names';
  import translate from '../Mixins/Translate';
  import DataTypes from '../../enums/data-types';

  /**
   * This component contains the part of the Edit Visit UI where a customer is selected,
   * including opening a popup if the user should be selected from a list,
   * and determining the current geolocation.
   */
  export default {
    name: 'customer-select',
    props: ['customer', 'editMode'],
    emits: [ComponentEventNames.updated],
    mixins: [translate],
    data() {
      return {
        customerMessage: '',
        customerMessage2: false,
        showOpenGeoHelp: false,
        customersLoading: false,
      };
    },
    computed: {
      ...mapState(['user']),
      ...mapState('LocationStore', ['location', 'isLoadingLocation', 'locationError', 'isGeolocationSupported']),
      ...mapState('VisitPlanStore', ['currentVisitPlan']),
      ...mapGetters('VisitPlanStore', ['isVisitPlanState']),
    },
    watch: {
      locationError: {
        handler: 'showError',
        immediate: true,
      },
      isGeolocationSupported(newValue) {
        if (newValue === false) {
          this.customerMessage = this.x('cannotFindYourPosition');
        }
      },
      async location(newValue) {
        await this.getNearestCustomer(newValue);
      },
    },
    async created() {
      if (this.customer) {
        const c = this.customer;
        this.updateCustomerMessage(c.name, `${c.postalCode} ${c.city}`);
      } else {
        await this.$store.dispatch('LocationStore/requestLocation');
      }
    },
    methods: {
      updateCustomer(customer) {
        let newCustomer = customer;
        if (Array.isArray(newCustomer)) {
          newCustomer = newCustomer[0];
        }
        if (newCustomer.error) {
          this.updateCustomerMessage(newCustomer.error.message);
          return;
        }

        this.$emit(ComponentEventNames.updated, newCustomer);

        this.updateCustomerMessage(newCustomer.name, `${newCustomer.postalCode} ${newCustomer.city}`);
        this.showOpenGeoHelp = false;
      },
      updateCustomerMessage(message, message2) {
        console.log('customer message');
        this.customerMessage = message;
        this.customerMessage2 = message2;
      },
      async getNearestCustomer(coordinates) {
        let url;
        if (this.user.userRole === 'external') {
          const { latitude: lat, longitude: lng } = coordinates;
          if (this.currentVisitPlan != null) {
            const visitPlanId = this.currentVisitPlan.id;
            url = `customers/nearest/fromvisitplan?lat=${lat}&lng=${lng}&visitplanid=${visitPlanId}`;
          } else {
            url = `customers/nearest/fromvisitplanforuser?lat=${lat}&lng=${lng}&userid=${this.user.id}`;
          }
        } else {
          url = `customers/nearest?lat=${coordinates.latitude}&lng=${coordinates.longitude}&channelonly=true`;
        }

        this.$store.commit('LocationStore/setIsLoadingLocation', true);
        const customer = await httpGet(url);
        this.updateCustomer(customer);
        this.$store.commit('LocationStore/setIsLoadingLocation', false);
      },
      showError(error) {
        if (error == null) return;
        switch (error.code) {
          case error.PERMISSION_DENIED:
            this.customerMessage = this.x('locationServicesDisabled');
            this.showOpenGeoHelp = true;
            break;
          case error.POSITION_UNAVAILABLE:
          case error.TIMEOUT:
          default:
            this.customerMessage = this.x('cannotFindYourPosition');
            break;
        }
      },
      async openGeoHelp() {
        const component = defineAsyncComponent(() => import('./GeoHelp'));
        this.$store.commit('pushPopup', {
          component,
          title: this.x('geoHelp'),
          direction: 'right',
        });
      },
      async openCustomerList() {
        const SelectorList = defineAsyncComponent(() => import('../SelectorList/SelectorList'));
        this.$store.commit('pushPopup', {
          component: SelectorList,
          direction: 'left',
          title: this.x('chooseCustomer'),
          params: {
            type: DataTypes.customers,
            callback: this.updateCustomer,
          },
        });
      },
    },
  };
</script>
