<template>
  <div>
    <p>
      <button class="lsc-button lsc-rounded half-button cover-button"
              :disabled="isCoverImage || isCustomerImage"
              @click="setCoverImage">
        {{  x('coverImage') }}&nbsp;<span :class="{ highlighted: isCoverImage }"
                                          class="button-icon"><icon name="fa-star"/></span>
      </button>
      <button class="lsc-button lsc-rounded half-button customer-image-button"
              :disabled="isCoverImage"
              @click="toggleCustomerImage">
        {{ x('customerImage') }}
        <span :class="{ highlighted: isCustomerImage }" class="button-icon">
          <icon name="fa-home"/>
        </span>
      </button>
    </p>
    <p class="small-text">
      {{ x('imageCannotBeBothCoverAndCustomerImage')}}
    </p>
    <p class="image-comment-paragraph">
      <textarea name="image-comment" class="image-comment"
                :placeholder="x('commentDots')"
                v-model="editedItem.comment"
                maxlength="240"
      />
    </p>
    <p>
      <delete-button delete-label="delete" class="wide-button"
                     @click="deleteImage"/>
    </p>

  </div>
</template>

<script>
  import Translate from '@/components/Mixins/Translate';
  import DeleteButton from '@/components/Survey/InputFields/DeleteButton';
  import ComponentEventNames from '@/enums/component-event-names';

  export default {
    name: 'image-drawer',
    components: { DeleteButton },
    props: ['modelValue', 'isCoverImage', 'isCustomerImage'],
    emits: [
      'delete-image',
      'cover-image',
      'toggle-customer-image',
      ComponentEventNames.updateModelValue,
    ],
    mixins: [Translate],
    data() {
      return {
        editedItem: { ...this.modelValue },
      };
    },
    watch: {
      modelValue(newValue) {
        this.editedItem = newValue;
      },
      editedItem: {
        handler(newValue) {
          this.$emit(ComponentEventNames.updateModelValue, newValue);
        },
        deep: true,
      },
    },
    methods: {
      setCoverImage() {
        console.debug('setCoverImage');
        if (this.isCoverImage) return;
        this.$emit('cover-image', this.editedItem);
      },
      deleteImage() {
        this.$emit('delete-image', this.editedItem);
      },
      toggleCustomerImage() {
        this.$emit('toggle-customer-image', !this.isCustomerImage);
      },
    },
  };
</script>

<style scoped lang="scss">
  .small-text {
    font-size: 10px;
  }

  .half-button {
    margin: 6px 0;
    width: calc(50% - 5px);
    text-align: center;
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;

    &:nth-of-type(2) {
      margin-left: 10px;
    }
  }

  .wide-button {
    margin: 6px 0;
    width: 100%;
    text-align: center;
    font-size: 14px;
  }

  .lsc-button i {
    margin: 0 -5px 0 0;
    font-size: 20px;
  }

  .customer-image-button {
    margin-top: 6px;
    background-color: var(--ThemeIdeaButton);
    color: var(--ThemeIdeaButtonText);
    border-color: var(--ThemeIdeaButtonBorder);

    .highlighted {
      color: var(--ThemeButtonGlowingDark) !important;
      text-shadow: 0 0 14px #ffd700 !important;
    }
  }

  .cover-button .highlighted {
    color: var(--ThemeButtonGlowing) !important;
    text-shadow: 0 0 14px #ffd700 !important;
  }

  .right-floating-icon {
    position: absolute;
    right: 10px;
  }

  .button-icon {
    vertical-align: middle;
  }

  .image-comment-paragraph {
    padding: 10px 0;

    .image-comment {
      width: 100%;
      color: #636363;
    }
  }
</style>
