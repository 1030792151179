// TODO: Make CompositeAPI function instead of helper function. Then use vuex directly instead of
// getting preselectedCustomer as a parameter.
/**
 *
 * @param {string} userId
 * @param {string} userRole
 * @param {string} reactionType
 * @param {object} [preselectedCustomer]
 * @returns {{campaigns: [], reactionType: *, customerId: null, userRole: *, userId: *,
 * pictures: [], firstPictureName: string, customer: [], products: []}}
 */
export function createNewVisit(userId, userRole, reactionType, preselectedCustomer) {
  return {
    userId,
    userRole,
    pictures: [],
    firstPictureName: '',
    customer: preselectedCustomer == null ? [] : [preselectedCustomer],
    customerId: preselectedCustomer == null ? null : preselectedCustomer.id,
    products: [],
    campaigns: [],
    reactionType,
  };
}
